/* eslint-disable */
import React from "react";
import { useQueryClient } from "react-query";

import t from "../../lib/translate";
import { useCompare } from "../../lib/service/compare/useCompare";
import { useRemoveFromCompare } from "../../lib/service/compare/useRemoveFromCompare";
import ContentHeaderCms from "../../content-elements/contentHeaderCms";

import Section from "../../component-elements/content-section";
import LoadingLabel from "../LoadingButton/Label";

import * as styles from "./component.module.scss";

const ignoredAttributeCodes = ["short_description"];

const Header = () => (
	<ContentHeaderCms>
		<h1 className={styles.headline}>{t`compare_headline`}</h1>
	</ContentHeaderCms>
);

const Compare = () => {
	const queryClient = useQueryClient();
	const removeItem = useRemoveFromCompare(queryClient);

	const { isLoading, isError, data: { attributes, items } = {} } = useCompare();

	if (isLoading) {
		return (
			<>
				<Header />
				<Section background="Weiß">
					<LoadingLabel />
				</Section>
			</>
		);
	}

	if (isError) {
		return (
			<>
				<Header />
				<Section background="Weiß" noGrid>{t`compare_load_err`}</Section>
			</>
		);
	}

	if (!items || items?.length === 0) {
		return (
			<>
				<Header />
				<Section background="Weiß" noGrid>
					{t`compare_empty_label`}
				</Section>
			</>
		);
	}

	return (
		<>
			<Header />
			<Section background="Weiß">
				<div className="col-16">
					<ul className={styles.itemList}>
						{items.map(({ product: { id, name } }) => (
							<li key={id}>
								{name}
								<button
									type="button"
									className={`icon icon-gold icon-delete ml-5 ${styles.btnDelete}`}
									onClick={() => removeItem.mutate({ id })}
								>
									<span className="sr-only">
										{t("compare_remove_item_label", name)}
									</span>
								</button>
							</li>
						))}
					</ul>
				</div>
			</Section>
			<Section
				fullWidth
				background="Weiß"
				className={`${styles.compare}${
					items?.length >= 5 ? ` ${styles.slim}` : ""
				}`}
			>
				<table>
					<thead>
						<tr>
							<th>&nbsp;</th>
							{items.map(({ product: { id, name } }) => (
								<th key={id}>{name}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{attributes.map(({ code, label }) => (
							<tr key={code}>
								<td className={styles.attributeLbl}>{label}</td>
								{items.map(
									({ product: { id, name }, attributes: itemAttributes }) => {
										const attrib = itemAttributes.find(
											({ code: itemAttribCode }) => itemAttribCode === code
										);
										if (ignoredAttributeCodes.includes(code) || !attrib) {
											return null;
										}

										if (code === "description") {
											return (
												// eslint-disable-next-line react/no-danger
												<td
													key={id}
													data-lbl={name}
													dangerouslySetInnerHTML={{ __html: attrib.value }}
												/>
											);
										}

										return (
											<td key={id} data-lbl={name}>
												{attrib.value}
											</td>
										);
									}
								)}
							</tr>
						))}
					</tbody>
				</table>
			</Section>
		</>
	);
};

export default Compare;
