import { useMutation } from 'react-query';
import { removeItem } from './api/mutate';

import { useCompareQueryKey } from './useCompare';

const useRemoveFromCompare = (queryClient) => useMutation(({ id }) => removeItem(id), {
  onMutate: async ({ id }) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(useCompareQueryKey);

    // Snapshot the previous value
    const snapshot = queryClient.getQueryData(useCompareQueryKey) || [];

    // new value
    const update = {
      ...snapshot,
      items: snapshot.items.filter(({ product: { id: snapshotItemId } }) => snapshotItemId !== id),
    };

    // Optimistically update to the new value
    queryClient.setQueryData(useCompareQueryKey, () => update);

    // Return a context object with the snapshotted value
    return { snapshot, update };
  },
  onError: (err, newData, context) => {
    queryClient.setQueryData(useCompareQueryKey, context.snapshot);
  },
  onSettled: () => {
    queryClient.invalidateQueries(useCompareQueryKey);
  },
});

// eslint-disable-next-line import/prefer-default-export
export { useRemoveFromCompare };
