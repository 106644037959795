import React from 'react';

import t from '../lib/translate';

import Layout from '../layouts/default';

import Helmet from '../components/helmet';
import Compare from '../components/Compare';

const CompareTemplate = () => (
  <Layout>
    <Compare />
    <Helmet titleFallback={t`compare_seo_title`} metaRobots="noindex-nofollow" />
  </Layout>
);

export default CompareTemplate;
